import RShared from './shared.routes'
import RAdmin from './admin.routes'
import RMerchant from './merchant.routes'

const combinedRoutes = []

export default combinedRoutes.concat(
  RAdmin,
  RShared,
  RMerchant
)
