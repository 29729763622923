import Vue from 'vue'

Vue.component('iq-card', require('@/components/miscs/cards/iq-card').default)
Vue.component('Lottie', require('@/components/miscs/lottie/Lottie').default)
Vue.component('Progressbar', require('@/components/miscs/progressbar/Progressbar').default)

Vue.component('tab-nav', require('@/components/miscs/tab/tab-nav').default)
Vue.component('tab-nav-items', require('@/components/miscs/tab/tab-nav-items').default)
Vue.component('tab-content', require('@/components/miscs/tab/tab-content').default)
Vue.component('tab-content-item', require('@/components/miscs/tab/tab-content-item').default)
Vue.component('TimeLine', require('@/components/miscs/timeline/TimeLine').default)
