import ApiService from '@/services/core/api.service'

const SWebhook = {

  async getCalls (query = '') {
    return ApiService.get(`admin/logs/webhook-calls?${query}`)
  },

  async getLogs (query = '') {
    return ApiService.get(`admin/logs/webhook-logs?${query}`)
  },

  async getEvents (query = '') {
    return ApiService.get(`admin/logs/webhook-events?${query}`)
  }
}

export default SWebhook
