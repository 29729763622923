import ApiService from '@/services/core/api.service'

const SMerchant = {
  async get (query = '') {
    return ApiService.get(`admin/clients?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/clients', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/clients/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`admin/clients/${payload.id}`)
  }
}

export default SMerchant
