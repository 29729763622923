import store from '@/store'
import { SSharedAuth } from '@/services'

export default async (_to, _from, next) => {
  if (!store.getters['auth/check'] && store.getters['auth/token']) {
    await SSharedAuth.getUserProfile().then(({ data }) => {
      store.dispatch('auth/fetchUserSuccess', {
        id: data.id,
        email: data.email,
        username: data.username,
        role: data.role,
        name: data.name
      })
    }).catch(() => {
      store.dispatch('auth/fetchUserFailure')
    })
  }

  next()
}
