function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/shared/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RShared = [
  {
    path: '',
    name: 'shared.redirect',
    authorizedRole: 'shared',
    redirect: { name: 'shared.login' }
  },
  {
    path: '/sign-in',
    component: view('SignIn'),
    name: 'shared.login',
    authorizedRole: 'shared',
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/components',
    component: view('Components'),
    name: 'shared.components',
    authorizedRole: 'shared',
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/landing-page',
    component: view('LandingPage'),
    name: 'shared.landing-page',
    authorizedRole: 'shared',
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/change-password',
    component: view('ChangePassword'),
    name: 'shared.change-password',
    authorizedRole: 'shared',
    meta: {
      breadcrumb: [
        {
          text: 'Account'
        },
        {
          text: 'Change Password',
          active: true
        }
      ]
    }
  },
  {
    path: '/auth/:provider/callback',
    component: view('SingleSignOn.vue'),
    name: 'shared.sso',
    authorizedRole: 'shared',
    meta: {
      layout: 'sso'
    }
  },
  {
    path: '*',
    component: view('404.vue'),
    name: 'shared.page-not-found',
    authorizedRole: 'shared',
    meta: {
      layout: 'auth'
    }
  }
]

export default RShared
