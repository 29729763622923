import ApiService from '@/services/core/api.service'

const SWallet = {
  async get (query = '') {
    return ApiService.get(`admin/merchants/webhook-urls?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/merchants/webhook-urls', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/merchants/webhook-urls/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`admin/merchants/webhook-urls/${payload.id}`)
  }
}

export default SWallet
