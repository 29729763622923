import axios from 'axios'
import { SError } from './error.service'

const SApi = {

  get (resource, config = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_URL}/${resource}`, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(SError.transform(error))
        })
    })
  },

  async post (resource, payload, config = {}) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API_URL}/${resource}`, payload, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(SError.transform(error))
        })
    })
  },

  put (resource, payload, config = {}) {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_API_URL}/${resource}`, payload, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(SError.transform(error))
        })
    })
  },

  delete (resource, config = {}) {
    return new Promise((resolve, reject) => {
      axios.delete(`${process.env.VUE_APP_API_URL}/${resource}`, config)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(SError.transform(error))
        })
    })
  }
}

export default SApi
