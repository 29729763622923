function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/merchant/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RUser = [
  {
    path: '/merchant/dashboard',
    component: view('Dashboard'),
    name: 'merchant.dashboard',
    authorizedRole: 'merchant'
  },
  {
    path: '/merchant/reports/qrph',
    component: view('reports/QRPh'),
    name: 'merchant.reports.qrph',
    authorizedRole: 'merchant',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Reports'
        },
        {
          text: 'QRPH',
          active: true
        }
      ]
    }
  }
  // {
  //   path: '/merchant/dashboard',
  //   component: view('Dashboard'),
  //   name: 'merchant.dashboard',
  //   authorizedRole: 'merchant'
  // },
  // {
  //   path: '/merchant/reports/qrph',
  //   component: view('reports/QRPh'),
  //   name: 'merchant.reports.qrph',
  //   authorizedRole: 'merchant',
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Home'
  //       },
  //       {
  //         text: 'Reports'
  //       },
  //       {
  //         text: 'QR Ph',
  //         active: true
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/merchant/reports/gcash',
  //   component: view('reports/GCash'),
  //   name: 'merchant.reports.gcash',
  //   authorizedRole: 'merchant',
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Home'
  //       },
  //       {
  //         text: 'Reports'
  //       },
  //       {
  //         text: 'GCash',
  //         active: true
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/merchant/reports/swift-pay',
  //   component: view('reports/SwiftPay'),
  //   name: 'merchant.reports.swift-pay',
  //   authorizedRole: 'merchant',
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Home'
  //       },
  //       {
  //         text: 'Reports'
  //       },
  //       {
  //         text: 'Swift Pay',
  //         active: true
  //       }
  //     ]
  //   }
  // }
]

export default RUser
