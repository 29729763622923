import ApiService from '@/services/core/api.service'

const SReport = {

  async getQRPh (query = '') {
    return ApiService.get(`merchant/exports/qrph?${query}`)
  }

  // async getQRPh (query = '') {
  //   return ApiService.get(`merchant/exports/qrph?${query}`)
  // },

  // async getGCash (query = '') {
  //   return ApiService.get(`merchant/exports/gcash-h5?${query}`)
  // },

  // async getSwiftPay (query = '') {
  //   return ApiService.get(`merchant/exports/swiftpay-gcash?${query}`)
  // }

}

export default SReport
