import ApiService from '@/services/core/api.service'

const SReport = {

  async getQRPh (query = '') {
    return ApiService.get(`admin/reports/qrph?${query}`)
  },

  async getGCash (query = '') {
    return ApiService.get(`admin/reports/gcash-h5?${query}`)
  },

  async getSwiftPay (query = '') {
    return ApiService.get(`admin/reports/swiftpay-gcash?${query}`)
  }

}

export default SReport
