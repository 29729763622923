<template>
  <div class="iq-progress-bar">
    <span
      ref="progress"
      :class="`bg-${color}`"
      style="transition: 'width 2s ease 0s'"
      :data-value="value"
    />
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'Progressbar',
  props: {
    value: { type: Number, default: 0 },
    color: { type: String, default: 'primary' }
  },
  mounted () {
    this.$nextTick()
    setTimeout(() => {
      core.progressInit(this.$refs.progress)
    }, 100)
  }
}
</script>
