import ApiService from '@/services/core/api.service'

const SList = {

  async getBanks ($query = '') {
    return ApiService.get(`shared/list/banks?${$query}`)
  },

  async getSystemOptions ($query = '') {
    return ApiService.get(`shared/list/system-options?${$query}`)
  }
}

export default SList
