import ApiService from '@/services/core/api.service'

const SUser = {
  async get (query = '') {
    return ApiService.get(`admin/users?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/users', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/users/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`admin/users/${payload.id}`)
  }
}

export default SUser
