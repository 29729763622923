import ApiService from '@/services/core/api.service'

const SCredential = {
  async get (query = '') {
    return ApiService.get(`admin/merchants/credentials?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/merchants/credentials', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/merchants/credentials/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`admin/merchants/credentials/${payload.id}`)
  }
}

export default SCredential
