function view (path) {
  return () => import('@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const RAdmin = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/accounts/users',
    component: view('UserAccounts'),
    name: 'admin.accounts.users',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Accounts'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/accounts/merchants',
    component: view('Merchants'),
    name: 'admin.accounts.merchants',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Accounts'
        },
        {
          text: 'Merchants',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/services/terminals',
    component: view('Banks'),
    name: 'admin.services.terminals',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Services'
        },
        {
          text: 'Banks',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/services/qrph',
    component: view('services/QRPh'),
    name: 'admin.services.qrph',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Services'
        },
        {
          text: 'QR Ph',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/services/gcash',
    component: view('services/GCash'),
    name: 'admin.services.gcash',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Services'
        },
        {
          text: 'GCash',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/services/swift-pay',
    component: view('services/SwiftPay'),
    name: 'admin.services.swift-pay',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Services'
        },
        {
          text: 'Swift Pay',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/webhooks/calls',
    component: view('webhooks/Calls'),
    name: 'admin.webhooks.calls',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Webhooks'
        },
        {
          text: 'Calls',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/webhooks/events',
    component: view('webhooks/Events'),
    name: 'admin.webhooks.events',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Webhooks'
        },
        {
          text: 'Events',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/webhooks/logs',
    component: view('webhooks/Logs'),
    name: 'admin.webhooks.logs',
    authorizedRole: 'admin',
    meta: {
      breadcrumb: [
        {
          text: 'Webhooks'
        },
        {
          text: 'Logs',
          active: true
        }
      ]
    }
  }
]

export default RAdmin
